<template>
<div id="AwardDetails">
    {{AwardSlug}} <br>
    {{AwardData}}
     <v-btn class="secondary" @click="$router.go(-1)">go back</v-btn>
    <v-btn color="primary " @click="$router.push('/editaward/' + AwardSlug)">Edit</v-btn>
    <v-btn color="red darken-1 " @click="deleteDialog = true">Delete</v-btn>

    <!-- delete modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="290" transition="slide-y-transition">
    <v-card>
    <v-card-title class="headline">Confirm Delete?</v-card-title>
    <v-card-text>
        This action can't be undone!
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="primary" text @click="DeleteAward()">Delete</v-btn>
    </v-card-actions>
    </v-card>
    </v-dialog>
    <!-- delete dialog ends -->
</div>
</template>

<script>
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

export default {

    name: 'AwardDetails',

    metaInfo: {
        title: 'Awards',
    },
    
    computed: {

        AwardSlug(){         
          return this.$route.params.awardslug != null ? this.$route.params.awardslug : null;
        },

        AwardData(){
            if(this.AwardSlug != null) {
                let data =  this.$store.getters.GetAwardWithSlug(this.AwardSlug);
                return data.length != 0 ? data : this.$router.push("/awards");
            }else{ return this.$router.push("/awards"); }             
        }
        
    },

    data(){return{

        deleteDialog: false,
    }},

    methods: {

        async DeleteAward(){
            // delete award
            this.deleteDialog = false;
            let slug = this.AwardSlug;
            // get document where student id equals selected one
            await firebase.firestore().collection("awards")
            .where("awardslug", "==", slug).get().then(
            (doc) => { 
                // delete post and comment
                firebase.firestore().collection("awards").doc(doc.docs[0].id).delete()
                .then((result) => { 
                    // batch delete
                    this.BatchDelete(slug)
                });
            }).catch((err) => { console.log(err); }); 
        },

        // batch delete awards from all db
        async BatchDelete(slug){
            // delete all award traces
            // First perform the query
            await firebase.firestore().collection("studentAwards").where("awardslug","==", slug).get()
            .then((querySnapshot) => {

                if(querySnapshot.docs.length != 0){

                    // Once we get the results, begin a batch
                    var batch = firebase.firestore().batch();
                    querySnapshot.forEach((doc) => {
                        // For each doc, add a delete operation to the batch
                        batch.delete(doc.ref);
                    });
                    // Commit the batch
                    batch.commit().then((s) => {this.$router.push('/awards');});

                }else{this.$router.push('/awards');}

            });
        },

    }


}
</script>

<style scoped>

</style>